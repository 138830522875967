/* main/body */
body {
    background-color: #EEEAEA;
    position: relative;
    padding-bottom: 2.5rem;
}

/* dark vs light theme */
.light-theme {
    background-color: #EEEAEA;
    color: rgb(22 22 29) !important;
    transition:  .5s;
}
.light-theme .topNav a, .topNav p{
    color: black;
}

.dark-theme {
    background-color: rgb(22 22 29);
    color: #EEEAEA !important;
    transition:  .5s;

}

.dark-theme .topNav a, .topNav p{
    color: #EEEAEA;
}

/* dark/vs component changes */
.dark-theme .landingCards {
    border-color: #EEEAEA;
    color: #EEEAEA !important;
    transition:  .5s;
}

.light-theme .landingCards {
    border-color: rgb(22 22 29);
    color: rgb(22 22 29) !important;
    transition:  .5s;
}


/* site-wide fonts */
h1, h2, h3, h4, h5, p, a {
    /* font-family v1 */
    /* font-family: futura-pt, sans-serif; */
    /* font family v2 */
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.5px;
}
/* navigation */
.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerContainer {
    background-color: rgb(22 22 29);
}

.logoMain {
    font-size: 25px;
    text-decoration: none !important;
}

.navLink {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex-grow: 5;
    text-decoration: none;
    display: flex;
    text-transform: uppercase;
}

.navLink:hover {
    cursor: pointer;
    color: black;
    text-decoration: underline;
}


/* skip main content link */
.skipToMain {
    left: 50%;
    position: absolute;
    transform: translateY(-100%);
    background: #fff;
    height: 30px;
    left: 0px;
    padding: 8px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
}
  
.skipToMain:focus {
    transform: translateY(0%);
}

/* contact btn */
.contactArrow {
    margin-left: 5px;
}

#back2Top {
    display: block; 
    position: fixed;
    opacity: 0; 
    bottom: 20px; 
    right: 30px; 
    z-index: 99; 
    border: solid 2px black;
    outline: none; 
    background-color: grey; 
    color: white; 
    cursor: pointer; 
    padding: 15px; 
    border-radius: 10px; 
    font-size: 18px; 
}

#back2Top:focus , #back2Top:hover {
    opacity: 1;
}


/* SECTION: Separators/Graphics/Random */
.hiddenDemarker {
    background-color: rgb(238 234 234);
    width: 14px;
    height: 1px;
    display: block;
    z-index: 2;
}

/* SECTION: Footer styling */
footer {
    position: relative;
    margin-bottom: -100px;
}
.footerContainer {
    width: 100%;
}

.footerContainer a , .footerContainer p {
    color: rgb(238 234 234);
}

.footerContainer a:hover {
    color: rgb(238 234 234);
    text-decoration: underline;
}

.copyright {
    text-align: center;
}