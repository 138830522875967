.centralLandingChoice {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    padding-right: 2em;
    padding-left: 2em;
    justify-content: center;
}

.blackAlbumChoice {
    margin: 4em;
    text-align: center;
}

.whiteAlbumChoice {
    margin: 4em;
    text-align: center;
}

/* button toggle */
#button-1 {
    background-color: black;
}
#button-1 .knobs:before
{
    content: 'W';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 10px;
    color: black;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #EEEAEA;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before
{
    content: 'B';
    left: 42px;
    color:#EEEAEA;
    background-color: black;
}

#button-1 .checkbox:checked ~ .layer
{
    background-color:white;
}

#button-1 .knobs, #button-1 .knobs:before, #button-1 .layer
{
    transition: 0.3s ease all;
}

.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button
{
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r, .button.r .layer
{
    border-radius: 100px;
}

.button.b2
{
    border-radius: 2px;
}

.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    transition: 0.3s ease all;
}

/* album content */

.contentLanding {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1;
    grid-template-areas: 
      "FarLeft FarRight"
}

.largeH2 {
    font-size: 45px;
}

/* album images top page */
.w_albumLandingShowCase {
    max-width: 500px;
    border-radius: 10px;
    grid-area: FarRight;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
}

.b_albumLandingShowCase {
    max-width: 500px;
    border-radius: 10px;
    grid-area: FarLeft;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
}

/* textboxes for albums */

.titleBAlbums {
    margin-left: 2em;
    margin-right: 2em;
    text-align: justify;
}

.titleWAlbums {
    position: relative;
    margin-left: 2em;
    margin-right: 2em;
    text-align: justify;
}

/* showcase mini album cover vinyl styles */

/* container parent */

.vinylAlbumStyle {
    max-width: 15em;
    padding: 1em;
    margin-right: 1em;
    margin-left: 1em;
    transition: all .2s ease-in-out; 
    z-index: 100;
}


/* hover anim */
.vinylAlbumStyle:hover {
    transform: scale(1.1); 
}


/* ===SECONDARY SECTION */

.stylizedPtoHeading2 {
    font-size: 6em;
}

.headSecSection {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

/* sliding cards */
.gridCardsContainer {
    display: grid;
    grid-template-columns: 25% 25% 25% 25% ;
    grid-template-rows: auto;
    grid-template-areas: 
      "farLeft midLeft midRight farRight";
    justify-items: center;
    align-items: center;
}

.landingCards {
    border: 1px solid;
    padding: 1em;
    min-height: 100%;
    max-height: 100%;
    min-width: 60%;
    max-width: 60%;
}

.attitudeCard {
    grid-area: farLeft;
}

.remiCard {
    grid-area: midLeft;
}

.narraCard {
    grid-area: midRight;
}

.exploCard {
    grid-area: farRight;
}

/* =====THIRD SECTION */


.thirdSection {
   
}

.headThirdSection {
    text-align: right;
    margin-right: 2em;
    margin-left: auto;
}

.artistPhoto {
    position: absolute;
    max-width: 30em;
    border-radius: .5em;
    margin-left: 5em;
    margin-right: 50em;

}

.artistBio {
    text-align: justify;
    margin-right: 8em;
    margin-left: 50em;
    padding-bottom: 1em;
}

.clearDivider {
    padding-top: 5em;
    padding-bottom:5em;
    height:5em;
}